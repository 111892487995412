/* Overall container styling */
.EducationalQual {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  color: #fff;
}

/* Title styling */
.EducationalQual h1 {
  font-size: 2em;
  text-align: center;
}

/* Individual education box styling */
.EduBox {
  margin-bottom: 20px;
  padding: 15px;
  overflow: hidden; /* Clear the float to contain floated elements */
}

/* Education title styling */
.EduTitle {
  font-size: 1.5em;
  color: #fff;
  text-align: center;
}

/* Education content styling */
.EduBox article {
  margin-top: 15px;
  overflow: hidden;
  display: flex; /* Use flexbox to control layout */
  flex-wrap: wrap; /* Allow content to wrap onto the next line */
}

/* Education description styling */
.EduDesc {
  color: #aaa;
  flex: 1; /* Use flex property to allow it to take remaining space */
  margin-bottom: 15px; /* Add space between description and image */
  text-align: center;
}

.wrap {
  /* margin-right: 15px; */
  float: left;
  display: flex;
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */
}
/* Education image styling */
.EduImage {
  max-width: 50%; /* Adjust the width based on your preference */
  height: auto;
  border-radius: 4px;
  margin-left: 15px; /* Add margin for separation between description and image */
}

@media screen and (max-width: 600px) {
  /* Responsive styling for mobile devices */

  /* Education image styling for mobile */
  .EduImage {
    max-width: 100%; /* Make sure the image does not exceed its container */
    margin-left: 0; /* Remove left margin on mobile */
  }
}
