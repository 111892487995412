.Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}


.paperContent{
    align-items: center;
    display: flex;
    justify-content: space-between;
    /* border: 1px solid #ddd; */
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    max-width: 100%;
    height: 400px;
    gap: 50px;

}

.contentText{
    max-width: 800px;
    padding-left: 1rem;
    
}

.contentTitle{
    padding-top: 1rem;
    color:teal;
}
.contentTeam{
    color:#555;
    padding: 1rem 0;
}

.contentImage{
    max-width: 280px;
    max-height: fit-content;
    padding-left: 2rem;
    padding-top: 2rem;
}

@media screen and (max-width: 600px) {
    .Container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .paperContent {
        align-items: center;
        display: flex;
        flex-direction: column; /* Change to column for mobile layout */
        justify-content: space-between;
        border-radius: 8px;
        padding: 20px;
        margin-bottom: 20px;
        max-width: 100%;
        height: auto; /* Change fixed height to auto for variable content */
        gap: 20px; /* Adjust the gap between items */
    }
    
    .contentText {
        max-width: 100%; /* Occupy full width on smaller screens */
        padding: 0 1rem; /* Adjust padding */
    }
    
    .contentTitle {
        padding-top: 1rem;
        color: teal;
    }
    
    .contentTeam {
        color: #555;
        padding: 1rem 0;
    }
    
    .contentImage {
        max-width: 100%; /* Occupy full width on smaller screens */
        max-height: auto; /* Adjust height to maintain aspect ratio */
        padding-top: 1rem; /* Adjust padding */
    }
}