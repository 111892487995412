.ExtraCurricular {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;/* Set the height to 100% of the viewport height for vertical centering */
  
  }
  h1{
    text-align: center;
  }
  
  .Activities {
    display: grid;
    justify-content: center;
    gap: 20px; /* Add a gap between activity boxes */
 /* Center the activities horizontally */
  }
  
  .ActivityBox {
    margin-top: 20px;
    margin-bottom: 20px;


    overflow: hidden; /* Clear the float to
  }
  
  h1 {
    text-align: center;
    padding: 1.5rem;
    /* Center the heading horizontally */
  }
  
  h3 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    color: #fff;
  }
  
  p {
    color: #ddd;
  }
  