.Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}


.paperContent{
    align-items: center;
    display: flex;
    justify-content: space-between;
    /* border: 1px solid #ddd; */
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    max-width: 100%;
    height: 320px;
    gap: 50px;

}

.contentText{
    max-width: 620px;
    padding-left: 1rem;
    
}

.contentTitle{
    padding-top: 1rem;
    color:teal;
}
.contentTeam{
    color:#555;
    padding: 1rem 0;
}

.contentImage{
    max-width: 280px;
    max-height: fit-content;
    padding-left: 2rem;
    padding-top: 2rem;
}

