.works {
    margin: 0 auto;
    min-height: calc(100vh - 80rem);
    width: 100vw;
    max-width: 60rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 1rem;
}

.worksTitle {
    margin: 1rem 0;
    font-size: 3rem;
}

.worksDesc {
    font-weight: 300;
    font-size: 1rem;
    max-width: 45rem;
}

.worksImgs {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100vw;
    max-width: 65rem;
}

.worksImg {
    object-fit: cover;
    height: 20rem;
    margin: 0.5rem;
}

.workBtn {
    margin: 3rem 0;
    padding: 0.5rem 2.5rem;
    border: none;
    border-radius: 2rem;
    background: white;
    font-size: 1rem;
}

@media screen and (max-width:585px) {
    .worksImg {
        height: 48vw;
    }
}
.worksBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem; /* Add a gap between boxes */
  }
  
  .worksBoxes {
    width: 250px;
    height: 80px;
    border: #f0f0f0;
    border-style: solid;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 8px;
    box-sizing: border-box;
  }
  
  .worksBox {
    flex: 0 1 calc(50% - 1rem); /* Two columns, minus the gap */
    margin-bottom: 1rem;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow for depth */
  }
  
  .worksBox h3 {
    font-size: 1.1rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    color: #555;
  }
  

@media screen and (max-width: 767px) {
    .worksBox {
        flex: 0 1 calc(50% - 1rem);
    }
}

@media screen and (max-width: 480px) {
    .worksBox {
        flex: 1 1 calc(100% - 1rem);
    }
}
