.researchContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.researchContent {
    align-items: center;
    display: flex;
    justify-content: space-between;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    max-width: 80rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    height: fit-content;
}

.researchImage {
    max-height: 400px;
    min-width: 30%;
    padding-left: 2rem;
}

.DescList {
    list-style-type: none;
    padding: 0;
}

.DescLi {
    margin-bottom: 8px;
    color: #999;
}

@media screen and (max-width: 600px) {
    .researchContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .researchContent {
        align-items: center;
        display: flex;
        flex-direction: column; /* Change to column for mobile layout */
        justify-content: space-between;
        border: 1px solid #ddd;
        border-radius: 8px;
        padding: 20px;
        margin-bottom: 20px;
        max-width: 100%; /* Occupy full width on smaller screens */
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        height: auto; /* Change fixed height to auto for variable content */
    }
    
    .researchImage {
        padding-top: 1rem;
        max-width: 100%; /* Occupy full width on smaller screens */
        max-height: 400px;
        padding-left: 0; /* Remove padding for smaller screens */
        margin-bottom: 1rem; /* Add space between image and content on mobile */
    }
    
    .DescList {
        list-style-type: none;
        padding: 0;
    }
    
    .DescLi {
        margin-bottom: 8px;
        color: #999;
    }
    
    /* Media query for screens with a maximum width of 600px (adjust as needed) */

    
}