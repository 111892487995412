/* Carousel.css */
.carousel-container {
    justify-content: center;
    max-width: 200rem;
    margin: 0 auto;
  }

  .CarouselImg{
    
        object-fit: cover;
        width: 100%; /* Ensure the image takes 100% width of the container */
        height: 400px; /* Set the fixed height for the container */
        margin: 0.25rem;
        padding: 0.25rem;
    }
    
  
  .slick-prev,
  .slick-next {
    font-size: 24px;
    color: #000;
  }
  
  .slick-prev:hover,
  .slick-next:hover {
    color: #333;
  }
  
  .slick-dots {
    bottom: -30px;
  }
  
  .slick-dots li button:before {
    font-size: 14px;
    color: #666;
  }
  
  .slick-dots li.slick-active button:before {
    color: #000;
  }
  