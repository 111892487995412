  /* Overall container styling */
  .AchcationalQual {
    max-width: 60%;
    margin: 0 auto;
    padding: 20px;
  }

  /* Title styling */
  .AchcationalQual h1 {
    font-size: 2em;
    color: #fff;
    text-align: center;
  }

  /* Individual Achcation box styling */
  .AchBox {
    margin-bottom: 20px;
    padding: 15px;

    overflow: hidden; /* Clear the float to contain floated elements */
  }

  /* Achcation title styling */
  .AchTitle {
    font-size: 1.5em;
    color: #ddd;
  }

  /* Achcation content styling */
  .AchBox article {
    margin-top: 15px;
    overflow: hidden; /* Clear the float to contain floated elements */
  }

  /* Achcation description styling */
  .AchDesc {
    color: #aaa;
    }
.wrap{
  margin-right: 15px;
  float: right;
}
  /* Achcation image styling */
  .AchImage {
    position: relative;
    padding-top: 40px;
    max-height: 300px;
    margin-left: 5px;
    top:-40px
  }