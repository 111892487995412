.ProjectContainers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
.ProjectBox {
    align-items: center;
    display: flex;
    justify-content: space-between;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    max-width: 80rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    height: 300px;
  }
  
  .ProjectContent {
    width: 80%;
  }

  
  .ProjectImage img {
    max-height: 260px;
      }
  
  
  .ProjectTitle {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #ddd;
  }
  
  .ProjectCourse {
    display: block;
    margin-bottom: 15px;
    color: #666;
  }
  
  .DescList {
    list-style-type: none;
    padding: 0;
  }
  
  .DescLi {
    margin-bottom: 8px;
    color: #999;
  }
  @media screen and (max-width: 600px) {
    .ProjectContainers {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  }
  
  .ProjectBox {
      align-items: center;
      display: flex;
      flex-direction: column; /* Change to column for mobile layout */
      justify-content: space-between;
      border: 1px solid #ddd;
      border-radius: 8px;
      padding: 15px;
      margin-bottom: 20px;
      max-width: 80rem;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      height: auto; /* Change fixed height to auto for variable content */
  }
  
  .ProjectContent {
      width: 100%; /* Occupy full width on smaller screens */
  }
  
  .ProjectImage img {
      max-width: 100%; /* Make sure the image does not exceed its container */
      height: auto;
  }
  
  .ProjectTitle {
      font-size: 1.5rem;
      margin-bottom: 10px;
      color: #ddd;
  }
  
  .ProjectCourse {
      display: block;
      margin-bottom: 15px;
      color: #666;
  }
  
  .DescList {
      list-style-type: none;
      padding: 0;
  }
  
  .DescLi {
      margin-bottom: 8px;
      color: #999;
  }
}